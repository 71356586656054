import { useEffect, useState } from 'react';
import Checkbox from '@/Components/Checkbox';
import GuestLayout from '@/Layouts/GuestLayout';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import PrimaryButton from '@/Components/PrimaryButton';
import TextInput from '@/Components/TextInput';
import { Head, Link, useForm } from '@inertiajs/react';
// TODO: bootstrapを殺しているのでデザイン修正の時になんとかする
import '../../../scss/login.scss'

export default function Login({ status, canResetPassword }) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    // ログイン画面の表出
    let [isShow, setIsShow] = useState(false);

    const submit = (e) => {
        e.preventDefault();

        post(route('login'));
    };

    const toggleLogin = () => {
        setIsShow(!isShow);
    }

    return (
        <GuestLayout>
            <br />
            {status && 
            <div className="alert alert-success" id="message" role="alert">{status}</div>
            }
            <br /><br /><br /><br /><br />


            {!isShow &&
                <div className='row text-center init'>

                    <div className="form-row rounded-4 col-md-6 offset-3 pt-4 pb-5 d-grid gap-5">
                        <h1 className='fs-2'>新規アカウント登録</h1>
                        <button
                            className="btn btn-outline-primary btn-lg px-5 m-auto object-fit-contain"
                            onClick={() => location.href = route('authorize.application')}>はじめてご利用の方</button>
                        <div className="d-flex justify-content-around">
                            <a href='https://ainu-upopoy.jp/group-admission-reservation/' target="_blank" className=''>利用規約</a>
                            <a href='https://ainu-upopoy.jp/education/reserve/#:~:text=%E5%80%8B%E4%BA%BA%E6%83%85%E5%A0%B1%E3%81%AE%E5%8F%96%E3%82%8A%E6%89%B1%E3%81%84%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6' target="_blank" className=''>プライバシーポリシー</a>
                        </div>
                    </div>
                    <div className="col-md-6 offset-3 py-5 d-grid gap-3">
                        <h2 className='fs-5'>すでにアカウントをお持ちの方</h2>
                        <button onClick={toggleLogin} className="btn btn-outline-primary btn-lg px-5 m-auto object-fit-contain">ログイン</button>
                        <div>
                            <a href={route('password.request')} >パスワードを忘れた方</a>
                        </div>
                    </div>
                </div>
            }
            {isShow &&
                <form onSubmit={submit}>
                    <div className="row">
                        <div className="col-md-6 offset-3">
                            <div className="row">
                                <h1 className="mb-5 fs-2 text-center">ログイン</h1>

                                <div className="col-12 mb-4">
                                    <label htmlFor="email">メールアドレス</label>
                                    <TextInput
                                        id="email"
                                        type="email"
                                        name="email"
                                        value={data.email}
                                        className="form-control"
                                        autoComplete="your@email.com"
                                        isFocused={true}
                                        onChange={(e) => setData('email', e.target.value)}
                                    />
                                    {errors.email && <div className="text-danger">{errors.email}</div>}
                                </div>
                                <div className="col-12 mb-4">
                                    <label htmlFor="password">パスワード</label>
                                    <TextInput
                                        id="password"
                                        type="password"
                                        name="password"
                                        value={data.password}
                                        className="form-control"
                                        isFocused={true}
                                        onChange={(e) => setData('password', e.target.value)}
                                    />
                                    {errors.password && <div className="text-danger">{errors.password}</div>}
                                </div>
                                <div className="col-12 mb-4">
                                    <button className="btn btn-primary" type="submit">ログイン</button>
                                </div>
                                <div className="col-12 text-end">
                                    <a href="#" onClick={toggleLogin}>戻る</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            }

            {/* <Head title="Log in" />

            {status && <div className="mb-4 font-medium text-sm text-green-600">{status}</div>}

            <form onSubmit={submit}>
                <div>
                    <InputLabel htmlFor="email" value="Email" />

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData('email', e.target.value)}
                    />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4">
                    <InputLabel htmlFor="password" value="Password" />

                    <TextInput
                        id="password"
                        type="password"
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        onChange={(e) => setData('password', e.target.value)}
                    />

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="block mt-4">
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData('remember', e.target.checked)}
                        />
                        <span className="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <div className="flex items-center justify-end mt-4">
                    {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Forgot your password?
                        </Link>
                    )}

                    <PrimaryButton className="ml-4" disabled={processing}>
                        Log in
                    </PrimaryButton>
                </div>
            </form> */}

        </GuestLayout>
    );
}
